import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { RouteComponentProps } from "@reach/router";
import {
  AppBar,
  Button,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { planMeals } from "../service/meal-planner";
import PlannedMeals from "./PlannedMeals";

interface WeeklyPlannerPageProps extends RouteComponentProps {}

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: "5px 10px",
  },
  appBarTitle: {
    flexGrow: 1,
  },
  content: {
    marginTop: "50px",
  },
  paper: {
    maxWidth: "1000px",
    margin: "auto",
    padding: "20px 40px",
  },
  button: {
    marginTop: "20px",
    padding: "10px 20px",
  },
  mealInput: {
    width: "100%",
    marginTop: "20px",
  },
  mealCountInput: {
    width: "140px",
    marginTop: "20px",
  },
  plannedMeals: {
    marginTop: "50px",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3895c4",
    },
  },
});

const WeeklyPlannerPage = (props: WeeklyPlannerPageProps) => {
  const classes = useStyles({});

  const [meals, setMeals] = React.useState<string>("");
  const [mealCount, setMealCount] = React.useState<number>(7);
  const [plannedMeals, setPlannedMeals] = React.useState<string[]>(null);

  React.useEffect(() => {
    const mealsFromLocalStorage = localStorage.getItem("meals");

    if (mealsFromLocalStorage) {
      setMeals(mealsFromLocalStorage);
    }

    const mealCountFromLocalStorage = localStorage.getItem("mealCount");
    if (mealCountFromLocalStorage) {
      setMealCount(parseInt(mealCountFromLocalStorage));
    }
  }, [setMeals, setMealCount]);

  const onMealsChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setMeals(e.target.value);

  const onMealCountChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.value
      ? setMealCount(parseInt(e.target.value))
      : setMealCount(null);

  const onPlan = () => {
    const randomizedMeals = planMeals(meals.split("\n"), mealCount);
    setPlannedMeals(randomizedMeals);
    localStorage.setItem("meals", meals);
    localStorage.setItem("mealCount", mealCount.toString());
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <AppBar className={classes.appBar}>
          <Typography variant="h6" className={classes.appBarTitle}>
            Weekly Meal Planner
          </Typography>
        </AppBar>
        <div className={classes.content}>
          <Paper elevation={2} className={classes.paper}>
            <Typography>
              Deciding what to eat for dinner each and every week can be
              stressful. Take that stress out of your life by making a computer
              plan meals for you. Just enter in all the meal options you and
              your family like to eat and this app will randomly select meals
              for you.
            </Typography>
            <TextField
              label="Meal options"
              className={classes.mealInput}
              multiline
              rows={16}
              value={meals}
              onChange={onMealsChange}
              variant="outlined"
              inputProps={{
                "data-testid": "meal-options-input-field",
              }}
            />
            <div>
              <TextField
                label="Number of meals"
                className={classes.mealCountInput}
                value={mealCount}
                onChange={onMealCountChange}
                variant="outlined"
                inputProps={{
                  "data-testid": "meal-count-input-field",
                }}
              />
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                data-testid="plan-button"
                onClick={onPlan}
              >
                Plan meals
              </Button>
            </div>
            {plannedMeals && (
              <div className={classes.plannedMeals}>
                <PlannedMeals plannedMeals={plannedMeals} />
              </div>
            )}
          </Paper>
        </div>
      </div>{" "}
    </ThemeProvider>
  );
};

export default WeeklyPlannerPage;
