import _ from "lodash";

const planMeals = (meals: string[], plannedMealCount: number): string[] => {
  const mealCopy = [...meals];

  const randomizedMeals = _.flatten(
    _.times(plannedMealCount, () => {
      const index = _.random(0, mealCopy.length - 1);

      return _.pullAt(mealCopy, [index]);
    })
  );

  return randomizedMeals;
};

export { planMeals };
