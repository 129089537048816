import * as React from "react";
import { render } from "react-dom";
import { Router } from "@reach/router";
import WeeklyPlannerPage from "./components/WeeklyPlannerPage";

const App = () => {
  return (
    <Router>
      <WeeklyPlannerPage path="/*" />
    </Router>
  );
};

render(<App />, document.getElementById("root"));
